import React, { useRef } from "react";
import Paper from "@mui/material/Paper";
import { QRCodeSVG } from "qrcode.react";
import AppBar from "../Components/AppBar";
import Card from "../Components/card";
import { Typography, Box } from "@mui/material";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import logo from "../Images/gepf printer img v1.png";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Toolbar from '@mui/material/Toolbar';
import P1 from '../Images/sincerely-media-p-NQlmGvFC8-unsplash.jpg';
import P2 from '../Images/navi-honMN5SyAnM-unsplash.jpg';
import P3 from '../Images/navi-XUUBZRej_T0-unsplash.jpg';

function Ticket() {
  const location = useLocation();
  const {
    timestamp,
    eventType,
    firstName,
    lastName,
    ticket,
    area,
    helpWith,
    otherText,
  } = location.state || {};
  const paperRef = useRef();

  const handlePrint = () => {
    const printContent = paperRef.current;
    const printWindow = window.open("", "", "width=600,height=600");
    printWindow.document.write("<html><head><title>Print</title></head><body>");
    printWindow.document.write(printContent.innerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();

    // Add a button to close the print window after printing
    // const closeButton = printWindow.document.createElement('button');
    // closeButton.innerText = 'Close';
    // closeButton.onclick = () => printWindow.close();
    // closeButton.style.position = 'fixed';
    // closeButton.style.bottom = '10px';
    // closeButton.style.left = '10px';
    // printWindow.document.body.appendChild(closeButton);
  };

  return (
    <React.Fragment>
      <AppBar />
      <Toolbar/>
      <Toolbar/>
      <Card sx={{ marginTop: "5px" }} />
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          zIndex: -1,
          overflow: 'hidden',
        }}
      >
        <Carousel
          showThumbs={false}
          autoPlay
          infiniteLoop
          showStatus={false}
          showArrows={false}
          showIndicators={false}
          interval={5000}
        >
          <div>
            <img src={P1} alt="Slide 1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
          <div>
            <img src={P2} alt="Slide 2" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
          <div>
            <img src={P3} alt="Slide 3" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
        </Carousel>
      </Box>
      <Paper
        ref={paperRef}
        sx={{
          p: 2,
          margin: "5%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: "#FFFFFF",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          {/* Add an image here */}
          <img
            src={logo}
            alt="logo"
            style={{ width: "60%", marginBottom: "20px" }}
          />
          <Typography>Welcome to {eventType}!</Typography>
        </Box>

        <React.Fragment>
          <QRCodeSVG value="https://www.gepf.co.za/" />
          <Typography>First Name: {firstName}</Typography>
          <Typography>Last Name: {lastName}</Typography>
          <Typography>Location: {area}</Typography>
          <Typography>
            Date and Time: <br />
            {timestamp}
          </Typography>
          {helpWith !== "Other" && (
            <Typography>
              Help with: {helpWith}
            </Typography>
          )}
          {helpWith === "Other" && <Typography>Help with: {otherText}</Typography>}
          <Typography>Ticket: {ticket}</Typography>
        </React.Fragment>
      </Paper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1%",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#93AB4F",
            "&:hover": {
              backgroundColor: "#DF6E46",
            },
          }}
          onClick={handlePrint}
        >
          Print
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default Ticket;
